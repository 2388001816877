import { UseQueryResult, useQuery, useQueryClient, useMutation } from "react-query";
import getAPI from "../services/api";
import _ from "lodash";
const queryKey = "mappings";

export const useMappings = (): UseQueryResult<any> =>
  useQuery(
    [queryKey],
    () => {
      return getAPI().then((api) => api.get<any>("/mappings").then((res) => res.data));
    },
    { retry: 0 },
  );

const createMapping = async (body) => {
  const api = await getAPI();
  const result = await api.post("/mapping", body);
  return result.data;
};

export const useCreateMapping = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createMapping,
    onMutate: async (newMapping) => {
      await queryClient.cancelQueries([queryKey]);
      const previousMappings: any[] = queryClient.getQueryData([queryKey]) || [];
      const newMappings = [...previousMappings, newMapping];
      queryClient.setQueryData([queryKey], newMappings);
      return { previousMappings, newMappings };
    },
    onError: (err, _, context) => {
      const { previousMappings = undefined } = context || {};
      queryClient.setQueryData(["contents"], previousMappings);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });
    },
  });
};

export const useMapping = (mappingId: string | null): UseQueryResult<any> =>
  useQuery(["mapping", mappingId || "null"], () => {
    if (!mappingId) {
      return null;
    }
    return getAPI().then((api) => api.get<any>(`/mapping/${mappingId}`).then((res) => res.data));
  });

export const useFieldsMapping = (mappingId: string | null): UseQueryResult<any> =>
  useQuery(["fields-mapping", mappingId || "null"], () => {
    if (!mappingId) {
      return null;
    }
    return getAPI().then((api) =>
      api.get<any>(`/mapping/${mappingId}/fields-mapping`).then((res) => {
        const fields = res.data;
        //removing duplicated questionCode
        return _(fields)
          .groupBy("questionCode")
          .filter((g) => g.length === 1)
          .flatMap()
          .value();
      }),
    );
  });

async function updateFieldMapping(body) {
  const { mappingId, questionCode } = body;
  return getAPI().then((api) => api.put(`/mapping/${mappingId}/field-mapping/${questionCode}`, body).then((res) => res.data));
}

export const useMutateFieldMapping = (mappingId: string | null, questionCode: string | null) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateFieldMapping,
    // When mutate is called:
    onMutate: async (newFieldMapping) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(["fields-mapping", mappingId || "null"]);

      // Snapshot the previous value
      const previousMappings: any[] = queryClient.getQueryData(["fields-mapping", mappingId || "null"]) || [];

      const newFieldsMapping = previousMappings.map((d) => {
        if (d.questionCode === questionCode) {
          return {
            ...d,
            ...newFieldMapping,
          };
        }
        return d;
      });

      // Optimistically update to the new value
      queryClient.setQueryData(["fields-mapping", mappingId || "null"], newFieldsMapping);

      // Return a context with the previous and new todo
      return { previousMappings, newFieldMapping };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, _, context) => {
      const { previousMappings = undefined } = context || {};
      queryClient.setQueryData(["fields-mapping", mappingId || "null"], previousMappings);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["fields-mapping", mappingId || "null"] });
    },
  });
};
