import React, { useState, useMemo,  useEffect } from "react";
import { Button, Divider, Drawer, Select, Spin, Row, Col } from "antd";
import { FileSearchOutlined, GlobalOutlined, CalendarOutlined, DeploymentUnitOutlined, TagsOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useCountry } from "../../hooks/country";
import { useYear } from "../../hooks/year";
import { useSurveyType } from "../../hooks/surveyType";
import { useSurveys } from "../../hooks/surveys";
import { useSplit } from "../../hooks/split";
import { useFilters } from "../../hooks/filters";
import { useFieldsMapping } from "../../hooks/mappings"
import { useData } from "../../hooks/data"
import EditMappingForm from "../Mapping/EditMappingForm"
import _ from "lodash"

const ContextSelection = ({ children, drawer = false }) => {
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useCountry();
  const [year, setYear] = useYear();
  const [filters, setFilters] = useFilters();
  const [surveyType, setSurveyType] = useSurveyType();
  const [split, setSplit] = useSplit();
  const [survey, setSurvey] = useState(null)
  const [selectedQuestionCode, setSelectedQuestionCode] = useState(null);

  const { data: fieldsMapping, isLoading: isLoadingFieldsMapping } = useFieldsMapping(survey?.mappingId || null);
  const { data: surveys, isLoading: isLoadingSurveys } = useSurveys();
  const { data: stats, isLoading: isStatsLoading } = useData({ questionId: "all", surveyId: survey?.surveyId });

  const { years, countries, surveysTypes } = useMemo(() => {
    function optionsForField(surveys: any[], field: string) {
      if (!surveys) {
        return [];
      }
      const filteredSurveys = surveys
        .filter(d => field === "country" || !country || d.country === country)
        .filter(d => field === "country" || field === "year" || !year || d.year == year)
        .filter(d => field === "country" || field === "year" || field === "surveyType" || !surveyType || d.surveyType === surveyType)
  
      return _(Array.from(new Set(filteredSurveys.map((survey) => survey[field])))).orderBy().value();
    }
    
    return {
      years: optionsForField(surveys, "year"),
      countries: optionsForField(surveys, "country"),
      surveysTypes: optionsForField(surveys, "surveyType"),
    };
  }, [surveys, country, surveyType, year]);

  useEffect(() => {
    if(surveys){
      if(!countries.includes(country)){ 
        setCountry(null)
      } 
      if(!years.map(d => `${d}`).includes(`${year}`)){
        setYear(null)
      }
      if(!surveysTypes.map(d => `${d}`).includes(`${surveyType}`)){
        setSurveyType(null)
      }
    }

  }, [ countries, years, surveyType, country, surveys, year ])

  useEffect(() => {
    if(surveys && country && surveyType && year){
      setSurvey(surveys.find(s => s.country === country && s.year == year && s.surveyType === surveyType))
    }
  }, [ surveys, country, surveyType, year ])

  useEffect(() => {
    if(fieldsMapping){
      if(!fieldsMapping.find(f => f.questionCode === split)) setSplit(null)
    }
  }, [ fieldsMapping ])

  useEffect(() => {
    if(fieldsMapping && survey && filters && stats)
    {
      setFilters(filters.filter(filter => {
        const field = fieldsMapping.find(field => field.questionCode === filter.fieldName)
        //const values = field && _(survey.answerMaps[field.questionCode]).values().value()
        const values = field && _(stats.stats[0][`dist_${field.questionCode}`]).keys().value()
        return field && values && filter.fieldValue.every(v => values.includes(v))
      }))
    }
  }, [ fieldsMapping, survey, setFilters, stats ])

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getContent = () => {
    return  <Spin spinning={isLoadingSurveys}>
      <Divider orientation="left">Survey</Divider>
      <div style={{ marginTop: 16 }}>
        <h5>
          <GlobalOutlined /> Country
        </h5>
        <Select
          showSearch
          options={countries.map((country) => ({ label: country, value: country }))}
          value={country}
          placeholder=""
          style={{ width: "100%" }}
          onChange={(value) => setCountry(value)}
          allowClear
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        />
      </div>
      <div style={{ marginTop: 16 }}>
        <h5>
          <CalendarOutlined /> Year
        </h5>
        <Select
          showSearch
          options={years.map((year) => ({ label: year, value: year }))}
          value={year}
          placeholder=""
          style={{ width: "100%" }}
          onChange={(value) => setYear(value)}
          allowClear
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        />
      </div>
      <div style={{ marginTop: 16 }}>
        <h5>
          <TagsOutlined /> Type
        </h5>
        <Select
          showSearch
          options={surveysTypes.map((type) => ({ label: type, value: type }))}
          value={surveyType}
          placeholder=""
          style={{ width: "100%" }}
          onChange={(value) => setSurveyType(value)}
          allowClear
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        />
      </div>
      <Divider orientation="left">Split</Divider>
      <div style={{ marginTop: 16 }}>
        <h5>
          <DeploymentUnitOutlined /> Split
        </h5>
        <Select
          showSearch
          options={fieldsMapping ? fieldsMapping.filter(f => f.includeInSplit).map(f => ({ label: `${f.label || f.questionText} (${f.questionCode})`, value: f.questionCode })) : []}
          value={split}
          allowClear={true}
          placeholder=""
          style={{ width: "100%"}}
          onChange={(value) => setSplit(value)}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        />
      </div>
      <Spin spinning={isLoadingFieldsMapping}>
      <Divider orientation="left" style={{marginBottom: 0}}>Filters</Divider>
        <Row justify="end"><Button type="link" onClick={() => setFilters(undefined)}><CloseCircleOutlined/> Reset all</Button></Row>
        {fieldsMapping && <div style={{ marginTop: 16 }}>
          {fieldsMapping.filter(f => f.includeInFilter).map(f => <div key={f.questionCode} style={{ marginBottom: 16}}>
          <Row justify="space-between" align="middle">
            <Col span={20}>
              <h5>
                {f.label} <small style={{ color: "grey"}}>{f.questionCode}</small>
              </h5>
            </Col>
            <Col span={4}>
            <Button key="edit" type="link" onClick={() => setSelectedQuestionCode(f.questionCode)}>
              Edit
            </Button>
            </Col>
          </Row>
          {/* {
            survey && survey.answerMaps && !survey?.answerMaps[f.questionCode] && <h5 style={{textAlign: "center", color:"grey"}}>Variable not supported</h5>
          } */}
          { survey && stats && //survey.answerMaps && survey?.answerMaps[f.questionCode] && 
            <Select
              mode="multiple"
              options={_(stats.stats[0][`dist_${f.questionCode}`] || []).keys().map(d => ({ label: d, value: d })).value()}
              allowClear={true}
              placeholder=""
              style={{ width: "100%" }}
              value={filters ? filters.find(d => d.fieldName === f.questionCode)?.fieldValue || [] : []}
              onChange={(values) => setFilters([
                ...(filters || []).filter(d => d.fieldName !== f.questionCode), 
                values && values.length > 0 ? { fieldName: f.questionCode, fieldValue: values, filterType: "in" } : null
              ].filter(d => d))}
            />
          } 
          </div>)}
        </div>}
      </Spin>
    </Spin>
    }

  return (
    <div style={{ width: "100%" }}>
      {!drawer && <>
        <Row>
          <Col span={18}>{children}</Col>
          <Col span={6} style={{ height: "100%"}}>
            <div style={{ height: "calc(-80px + 100vh)", overflowY: "scroll", padding: 8 }}> {getContent()}</div> 
          </Col>
        </Row>
      </> 
      }
      {drawer && <>
        {children}
        <div style={{ position: "absolute", top: 16, right: 16 }}>
          <Button type="primary" size="large" onClick={showDrawer} shape="circle" icon={<FileSearchOutlined />} />
        </div>
        <Drawer title="Context Selection" placement="right" closable={false} onClose={onClose} open={open} getContainer={false}>
          {getContent()}
        </Drawer>
      </> 
      }
      <EditMappingForm
        questionCode={selectedQuestionCode}
        onClose={() => setSelectedQuestionCode(null)}
        mappingId={survey?.mappingId}
      />
    </div>
  );
};
export default ContextSelection;
