import { UseQueryResult, useQuery, useMutation, useQueryClient } from "react-query";
import getAPI from "../services/api";

export const useSurveys = (): UseQueryResult<any> =>
  useQuery(
    ["surveys"],
    () => {
      return getAPI().then((api) => api.get<any>("/surveys").then((res) => res.data));
    },
    { retry: 0 },
  );

export const useSurveyFieldsMapping = (surveyId: string | null): UseQueryResult<any> =>
  useQuery(["survey", surveyId || "null", "fields-mapping"], () => {
    if (!surveyId) {
      return null;
    }
    return getAPI().then((api) =>
      api.get<any>(`/survey/${surveyId}/fields-mapping`).then((res) =>
        res.data.map((d) => {
          d.valInProportion = JSON.parse(d.valInProportion);
          return d;
        }),
      ),
    );
  });

async function updateSurveyFieldMapping(body) {
  const { surveyId, questionCode } = body;
  return getAPI().then((api) => api.put(`/survey/${surveyId}/field-mapping/${questionCode}`, body).then((res) => res.data));
}

export const useMutateSurveyFieldMapping = (surveyId: string | null, questionCode: string | null) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSurveyFieldMapping,
    // When mutate is called:
    onMutate: async (newFieldMapping) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(["survey", surveyId || "null", "fields-mapping"]);

      // Snapshot the previous value
      const previousMappings: any[] = queryClient.getQueryData(["survey", surveyId || "null", "fields-mapping"]) || [];

      const newFieldsMapping = previousMappings.map((d) => {
        if (d.questionCode === questionCode) {
          return {
            ...d,
            ...newFieldMapping,
          };
        }
        return d;
      });

      // Optimistically update to the new value
      queryClient.setQueryData(["survey", surveyId || "null", "fields-mapping"], newFieldsMapping);

      // Return a context with the previous and new todo
      return { previousMappings, newFieldMapping };
    },
    // If the mutation fails, use the context we returned above
    onError: (err, _, context) => {
      const { previousMappings = undefined } = context || {};
      queryClient.setQueryData(["survey", surveyId || "null", "fields-mapping"], previousMappings);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["survey", surveyId || "null", "fields-mapping"] });
    },
  });
};
