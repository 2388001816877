import { BarChartOutlined, DatabaseOutlined, BookOutlined, FormOutlined, SmileOutlined, UserOutlined, LinkOutlined } from "@ant-design/icons";
import { Result, Spin } from "antd";
import React, { lazy } from "react";
import { useRouteError } from "react-router";
import AppLayout from "./AppLayout";
import { QueryParamProvider } from "use-query-params";
import HomeButton from "./components/UI/HomeButton";
import RetryButton from "./components/UI/RetryButton";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import ContextSelection from "./components/UI/ContextSelection";

const LoadingSpin = () => <Spin spinning={true} tip="Loading..." style={{ textAlign: "center", width: "100%", marginTop: "100px" }} />;

export const NotFound = () => <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." extra={<HomeButton />} />;
export const ErrorBoundary = () => {
  const error: any = useRouteError();
  return <Result status="500" title={error.toString()} subTitle="Sorry, something went wrong" extra={<RetryButton />} />;
};

export const routes = [
  {
    id: "root",
    path: "/",
    element: (
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <AppLayout />
      </QueryParamProvider>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        id: "home",
        path: "/",
        index: true,
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            {React.createElement(
              lazy(() => import("./components/Home/Home")),
              {},
            )}
          </React.Suspense>
        ),
        errorElement: <ErrorBoundary />,
        handle: {
          title: "Home",
        },
        hide: true,
      },
      {
        id: "survey-overview",
        path: "/survey-overview",
        index: true,
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            <ContextSelection>
              {React.createElement(
                lazy(() => import("./components/SurveyOverview/SurveyOverview")),
                {},
              )}
            </ContextSelection>
          </React.Suspense>
        ),
        errorElement: <ErrorBoundary />,
        handle: {
          title: "Survey Overview",
          icon: SmileOutlined,
        },
      },
      {
        id: "data-explorer",
        path: "/data-explorer",
        index: true,
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            {React.createElement(
              lazy(() => import("./components/DataExplorer/DataExplorer")),
              {},
            )}
          </React.Suspense>
        ),
        errorElement: <ErrorBoundary />,
        handle: {
          title: "Data explorer",
          icon: BarChartOutlined,
        },
      },
      {
        id: "survey-upload",
        path: "/surveys",
        requiredPermission: "ADMIN",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            {React.createElement(
              lazy(() => import("./components/Surveys/Surveys")),
              {},
            )}
          </React.Suspense>
        ),
        handle: {
          title: "Surveys",
          icon: DatabaseOutlined,
        },
      },
      {
        id: "mappings",
        path: "/mappings",
        requiredPermission: "ADMIN",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            {React.createElement(
              lazy(() => import("./components/Mappings/Mappings")),
              {},
            )}
          </React.Suspense>
        ),
        handle: {
          title: "Mappings",
          icon: LinkOutlined,
        },
      },
      {
        id: "mappings-edit",
        path: "/mappings/:mappingId",
        requiredPermission: "ADMIN",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            {React.createElement(
              lazy(() => import("./components/Mapping/Mapping")),
              {},
            )}
          </React.Suspense>
        ),
        handle: {
          title: "Mapping viewer",
          // icon: LinkOutlined,
        },
      },
      // {
      //   id: "survey-mapping",
      //   path: "/surveys/:surveyId",
      //   requiredPermission: "ADMIN",
      //   element: (
      //     <React.Suspense fallback={<LoadingSpin />}>
      //       {React.createElement(
      //         lazy(() => import("./components/SurveysMappings/SurveyMappings")),
      //         {},
      //       )}
      //     </React.Suspense>
      //   ),
      //   errorElement: <ErrorBoundary />,
      //   handle: {
      //     title: "Survey mappings",
      //   },
      // },
      {
        id: "contents",
        path: "/contents",
        requiredPermission: "EDITOR",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            {React.createElement(
              lazy(() => import("./components/Contents/Contents")),
              {},
            )}
          </React.Suspense>
        ),
        handle: {
          title: "Contents",
          icon: FormOutlined,
        },
      },
      {
        id: "new-content",
        path: "/contents/new",
        requiredPermission: "EDITOR",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            {React.createElement(
              lazy(() => import("./components/Content/Content")),
              {},
            )}
          </React.Suspense>
        ),
        handle: {
          title: "Create new content",
        },
      },
      {
        id: "content",
        path: "/contents/:contentId",
        requiredPermission: "EDITOR",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            {React.createElement(
              lazy(() => import("./components/Content/Content")),
              {},
            )}
          </React.Suspense>
        ),
        handle: {
          title: "Content editor",
        },
      },
      {
        id: "pages",
        requiredPermission: "VIEWER",
        path: "/pages",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            {React.createElement(
              lazy(() => import("./components/Pages/Pages")),
              {},
            )}
          </React.Suspense>
        ),
        handle: {
          title: "Published pages",
          icon: BookOutlined,
        },
      },
      {
        id: "page",
        path: "/pages/:pageId",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            {React.createElement(
              lazy(() => import("./components/Page/Page")),
              {},
            )}
          </React.Suspense>
        ),
        handle: {
          title: "Page viewer",
        },
      },
      {
        id: "users",
        path: "/users",
        requiredPermission: "ADMIN",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            {React.createElement(
              lazy(() => import("./components/Users/Users")),
              {},
            )}
          </React.Suspense>
        ),
        handle: {
          title: "Users",
          icon: UserOutlined,
        },
      },
      {
        id: "not-found",
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

const appendChildren = (routes) => routes.map((route) => (route?.children ? appendChildren(route.children) : route)).flat();

export const navigationMenuRoutes = appendChildren(routes)
  .filter((route) => !!route.handle?.icon)
  .filter((route) => !route.hide)
  .map((route) => ({
    id: route.id,
    path: route.path,
    title: route.handle?.title,
    icon: route.handle?.icon,
    requiredPermission: route.requiredPermission,
  }));
