import React, { useMemo, useEffect, useState } from "react";
import { Modal, Form, Input, Select, Row, Col, Switch, InputNumber } from "antd";
import { useParams } from "react-router-dom";
import { useFieldsMapping, useMutateFieldMapping } from "../../hooks/mappings";
import { QUESTION_TYPES } from "shared/Question";
import { METRIC_TYPES } from "shared/Metric";
import DistributionPlot from "../Plots/DistributionPlot";
import { InfoCircleOutlined } from "@ant-design/icons";

interface EditMappingFormProps {
  questionCode: string | null;
  onClose: () => void;
  mappingId?: string | null;
  stats?: Array<object> | null;
}
function EditMappingForm({ questionCode, onClose, mappingId = null, stats = null }: EditMappingFormProps) {
  const { mappingId: mappingIdParam } = useParams();
  const [showValInProportion, setShowValInProportion] = useState(false);
  const { data: fieldsMapping } = useFieldsMapping(mappingId || mappingIdParam || null);
  const mutateFieldMapping = useMutateFieldMapping(mappingId || mappingIdParam || null, questionCode || null);
  const selectedQuestion = useMemo(() => {
    if (fieldsMapping && fieldsMapping.length > 0) {
      return fieldsMapping.find((question) => question.questionCode === questionCode);
    }
    return {};
  }, [fieldsMapping, questionCode]);
  const [form] = Form.useForm();
  const onOk = () => {
    form.submit();
    onClose();
  };

  useEffect(() => {
    setShowValInProportion(selectedQuestion?.metric === "Custom proportion");
    form.setFieldsValue({ ...selectedQuestion, valInProportion: selectedQuestion?.valInProportion || [] });
  }, [selectedQuestion, form]);

  return (
    <Modal width="80%" onOk={onOk} onCancel={onClose} open={!!questionCode} destroyOnClose title={`Update mapping for question ${questionCode}`}>
      <Row>
        <Col span={12} style={{ padding: 24 }}>
          <Form
            labelAlign="left"
            form={form}
            // initialValues={initialValues}
            labelCol={{ span: 6, offset: 0 }}
            onFinish={(values) => {
              const newMapping = { ...selectedQuestion, ...values };
              mutateFieldMapping.mutate(newMapping);
            }}
            onValuesChange={(m) => setShowValInProportion(form.getFieldValue("metric") === "Custom proportion")}
          >
            <Form.Item label="Ignore" name="ignore" valuePropName="checked" labelCol={{ span: 6, offset: 0 }}>
              <Switch />
            </Form.Item>
            <Form.Item label="Question text" name="questionText">
              <Input disabled />
            </Form.Item>
            <Form.Item label="Question label" name="label">
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <div>
                  Additional info <InfoCircleOutlined />
                </div>
              }
              name="info"
            >
              <Input />
            </Form.Item>
            <Form.Item label="Question type" name="questionType" valuePropName="value">
              <Select>
                {QUESTION_TYPES.map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Metric" name="metric">
              <Select>
                {METRIC_TYPES.map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {questionCode && showValInProportion && (
              <Form.Item label="Values in proportion" name="valInProportion" labelCol={{ span: 6, offset: 2 }}>
                <Select mode="multiple" allowClear disabled={!stats}>
                  {stats &&
                    Object.keys(stats[`dist_${questionCode}`] || stats).map((value) => (
                      <Select.Option key={value} value={value}>
                        {value}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item label="Ranked" name="ranked" valuePropName="checked" labelCol={{ span: 6, offset: 0 }}>
              <Switch />
            </Form.Item>
            <Form.Item label="Include in Split" name="includeInSplit" valuePropName="checked" labelCol={{ span: 6, offset: 0 }}>
              <Switch />
            </Form.Item>
            <Form.Item label="Include in Filter" name="includeInFilter" valuePropName="checked" labelCol={{ span: 6, offset: 0 }}>
              <Switch />
            </Form.Item>
            {/* <Form.Item label="Negative flag" name="negativeFlag" valuePropName="checked" labelCol={{ span: 6, offset: 0 }}>
              <Switch />
            </Form.Item> */}
            <Form.Item label="Outcome" name="outcome">
              <Input />
            </Form.Item>
            <Form.Item label="Category" name="category">
              <Input />
            </Form.Item>
            <Form.Item label="Subcategory" name="subCategory">
              <Input />
            </Form.Item>
            <Form.Item label="Rank in subcategory" name="rankInSubCategory" labelCol={{ span: 6, offset: 0 }}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item label="Questions group" name="questionGroup" labelCol={{ span: 6, offset: 0 }}>
              <Input />
            </Form.Item>
            <Form.Item label="Description in group" name="descriptionInGroup" labelCol={{ span: 6, offset: 2 }}>
              <Input />
            </Form.Item>
          </Form>
        </Col>
        {stats && (
          <Col span={12} style={{ padding: 24, maxHeight: "60vh", overflowY: "scroll" }}>
            <h5>Distribution of values</h5>
            {questionCode && stats && (
              <>
                <DistributionPlot data={stats[`dist_${questionCode}`] || stats} />
                {/* {questionCode && JSON.stringify(stats[`dist_${questionCode?.toLocaleLowerCase()}`])} */}
              </>
            )}
          </Col>
        )}
      </Row>
    </Modal>
  );
}

export default EditMappingForm;
