import { useQueryParam, JsonParam } from "use-query-params";

export const useFilters = () => {
  const [filters, setFilters] = useQueryParam("filters", JsonParam);

  const setFilterWrapper = (filters) => {
    if(filters && filters.length > 0){
      setFilters(filters)
    } else {
      setFilters(undefined)
    }
  }
  //@ts-ignore
  return [filters, setFilterWrapper] as const;
};
