import { useQuery } from "react-query";
import getAPI from "../services/api";
import { DataConfig } from "shared/DataConfig";

export function useData(config: DataConfig) {
  return useQuery(
    ["data", JSON.stringify(config)],
    async () => {
      if (!config.questionId) {
        return null;
      }
      return getAPI().then((api) =>
        api.post("/data", config).then((res) => {
          return res.data;
        }),
      );
    },
    { enabled: !!config.surveyId, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, refetchInterval: false, retry: 1 },
  );
}
