import { useEffect, useRef, useState } from "react";
import * as Plot from "@observablehq/plot";
import _ from "lodash";
import numeral from "numeral";

const DistributionPlot = ({ data, order = null as Array<string> | null, color = null, sort = false }) => {
  const ref = useRef();
  const [undif, setUndif] = useState({ count: 0, prop: 0 });

  useEffect(() => {
    if (data) {
      const total = _(data).map().sum();
      setUndif({ count: data["undefined"] || 0, prop: (data["undefined"] || 0) / total });

      const numeric = _(data)
        .keys()
        .filter((k) => k !== "undefined")
        .value()
        .every((k) => !isNaN(+k));

      const dataList = _(data)
        .entries()
        .filter(([key, value]) => key !== "undefined")
        .map(([key, value]) => ({ value: numeric ? `${key}` : key, count: value, prop: value / (total - (data["undefined"] || 0)) }))
        .value();

      //@ts-ignore
      const width = ref.current.clientWidth;
      const chart = Plot.plot({
        //marginLeft: Math.min(300, width * 0.5),
        marginTop: 40,
        marginBottom: 20,
        style: { overflow: "visible", fontSize: "14px" },
        width: width,
        y: { inset: 5, label: null, axis: false, tickSize: 0, ...(order ? { domain: order } : null) },
        x: { grid: true, axis: "top", label: "Frequency", percent: true, tickFormat: (d) => `${d}%` },
        marks: [
          Plot.barX(
            dataList,
            Plot.groupY(
              { x: "proportion" },
              {
                x: "count",
                y: "value",
                sort: sort ? { y: "y" } : { y: "x", reverse: true },
                fill: color || "dodgerblue",
                rx: 10,
              },
            ),
          ),
          Plot.textX(dataList, {
            y: "value",
            x: (d) => 0,
            textAnchor: "start",
            dx: 8,
            fontSize: 14,
            fontWeight: "bold",
            fill: "black",
            stroke: "white",
            text: (d) => `${d.value} (${numeral(d.prop).format("0.[0]%")})`,
          }),
          Plot.ruleX([0]),
        ],
      });

      //@ts-ignore
      ref.current.append(chart);

      return () => chart.remove();
    }
  }, [data, color, order, sort]);

  return (
    <div>
      {/* @ts-ignore */}
      <div ref={ref} />
      {undif.count > 0 && (
        <h5 style={{ textAlign: "end", color: "grey" }}>
          {numeral(undif.count).format("0,0")} unanswered ({numeral(undif.prop).format("0.[0]%")})
        </h5>
      )}
    </div>
  );
};

export default DistributionPlot;
