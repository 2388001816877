import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { shouldSkipAuth } from "../App";

export const useUser = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (shouldSkipAuth) return;
    Auth.currentAuthenticatedUser().then((user) => {
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      user.attributes.groups = groups;
      if (groups?.indexOf("developers") > -1) {
        user.attributes.featureFlagAccess = true;
      } else {
        user.attributes.featureFlagAccess = false;
      }
      user.attributes.isAdmin = user.attributes.groups.includes("ADMIN");
      user.attributes.isEditor = user.attributes.groups.includes("EDITOR");
      setUser(user.attributes);
    });
  }, []);
  return user || {};
};
