import React from "react";
import { Layout } from "antd";

import UserMenu from "../UserMenu/UserMenu";
import "./HeaderMenu.css";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useMatches } from "react-router";

const { Header } = Layout;

const styles = {
  header: {
    background: "#fff",
    padding: 0,
    display: "flex",
    alignItems: "center",
    zIndex: 1,
    boxShadow: "0 3px 6px lightgrey",
    clipPath: "inset(0px 0px -6px 0px)",
    justifyContent: "space-between",
  },
  title: {
    margin: 0,
  },
};

function HeaderMenu({ collapsed, toggleCollapse }) {
  const Icon = collapsed ? MenuUnfoldOutlined : MenuFoldOutlined;
  const matches = useMatches();
  const title = matches?.[matches.length - 1]?.handle?.title || "";

  return (
    <Header style={styles.header}>
      <div className="header-menu-left">
        {toggleCollapse && ( // show only if we have toggleCollapse
          <Icon className="trigger" onClick={toggleCollapse} />
        )}
        <h2 style={styles.title}>{title}</h2>
      </div>
      <div className="header-menu-right">
        <UserMenu />
      </div>
    </Header>
  );
}

export default HeaderMenu;
