import React from "react";

const TstWarning = () => {
  const isLocalhost = window.location.host.includes("localhost");
  const prodURLs = ["royal-canin.eom.jetpack.ai", "d34zv2hbcirzw3.cloudfront.net"];
  const isCloudfront = window.location.host.indexOf("cloudfront") > -1;
  const prodURL = isCloudfront ? prodURLs[1] : prodURLs[0];
  return prodURLs.indexOf(window.location.host) === -1 && !isLocalhost ? (
    <div
      style={{
        position: "absolute",
        display: "block",
        zIndex: 200,
        width: "100%",
        backgroundColor: "red",
        color: "white",
        textAlign: "center",
      }}
    >
      Attention ! cet environnement est dédié au developpement. L'environnement de production est{" "}
      <a
        style={{
          color: "white",
          textDecoration: "underline",
          fontWeight: "bold",
        }}
        href={`https://${prodURL}`}
      >
        {prodURL}
      </a>
    </div>
  ) : null;
};

export default TstWarning;
