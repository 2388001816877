import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { navigationMenuRoutes } from "../../routes";
import React, { useMemo } from "react";
import { useUser } from "../../services/auth";

function NavigationMenu({ currentPath, collapsed }) {
  const user = useUser();
  let location = useLocation();

  const items = useMemo(() => {
    if (!user || !user.groups) {
      return [];
    }
    return navigationMenuRoutes
      .map((route) => ({
        disabled: route.disabled || false,
        key: route.id,
        requiredPermission: route.requiredPermission,
        icon: React.createElement(route.icon, {}),
        label: (
          <Link to={{ pathname: route.path, search: location?.search }}>
            <span>{route.title}</span>
          </Link>
        ),
      }))
      .filter((route) => {
        if (!user || !user.groups) {
          return false;
        }
        if (user?.isAdmin) {
          return true;
        }
        if (route.requiredPermission) {
          return user.groups.includes(route.requiredPermission);
        }
        return true;
      });
  }, [user, location]);

  return (
    <Menu
      items={items}
      mode="inline"
      selectedKeys={[currentPath]}
      collapsed={collapsed}
      style={{ borderRight: collapsed ? "1px solid #e8e8e8" : "none" }} // right border fix
    />
  );
}

export default NavigationMenu;
